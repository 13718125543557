import { Component, Input } from '@angular/core';

@Component({
  selector: 'expired',
  templateUrl: './expired.html',
  styleUrls: ['./expired.less']
})
export class ExpiredComponent {

  /**
   * Component shown when a game has timed out.
   */

  @Input() title: string;
  @Input() game:  string;

  constructor() { }

}
