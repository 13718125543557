<div class="home-link">
  <a href="/">
    <i class="material-icons">arrow_back</i>
    All Games
  </a>
</div>

<div [ngSwitch]="status">
  <div *ngSwitchCase="'initializing'">
    <h1 class="header center how-to">
      How to Play Scattergories
    </h1>

    <p class="rules">
      The computer will pick a letter and a number of categories. Try to think
      of a word that starts with that letter for each category.
    </p>

    <p class="rules">
      When the time runs out, each player votes what words should count and which
      should not. The winner is whichever player has the most points. Play as many
      rounds as you want!
    </p>

    <div class="center">
      <form ngNoForm action="/api/scattergories" method="POST" class="game-options">
        <button class="waves-effect waves-light btn-large" type="submit">
          Sounds Simple Enough
        </button>
      </form>
    </div>
  </div>
  <div *ngSwitchCase="'choosing'">
    <lobby game="scattergories"
           title="Scattergories"
           [room]="room"
           [currentPlayer]="player"
           [players]="players"
           [status]="status"
           (starting)="start($event)"> </lobby>
  </div>
  <div *ngSwitchCase="'waiting'">
    <lobby game="scattergories"
           title="Scattergories"
           [currentPlayer]="player"
           [players]="players"
           [status]="status"
           [room]="room"
           (starting)="start($event)"> </lobby>
  </div>
  <div *ngSwitchCase="'playing'">
    <div class="container timed">
      <h1 class="header center">
        Scattergories
      </h1>

      <div class="timer-holder">
        <timer [round]="round" [remaining]="remaining"> </timer>
      </div>

      <h5 class="center">
        Think of words words in each category that start with the letter <span class="letter">{{ letter }}</span>
      </h5>

      <div class="section">
        <table class="scattergories">
          <tr *ngFor="let category of categories; let row = index">
            <th>{{ category }}</th>
            <td>
              <input type="text"
                     [(ngModel)]="words[row]"
                     (ngModelChange)="wordEdited($event, row)"/>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'voting'">
    <h1 class="header center">
      Scattergories
    </h1>

    <h5 class="center">
      Which of these words fit the category "{{ category }}"?
    </h5>

    <div class="section">
      <div class="voting">
        <table >
        <tr *ngFor="let candidate of players">
          <ng-container *ngIf="judging[category][candidate]">
            <ng-container [ngSwitch]="duplicates[category].includes((judging[category][candidate] || '').toLowerCase())">
              <ng-container *ngSwitchCase="false">
                <td class="word">
                  {{ judging[category][candidate] }}
                </td>
                <td class="score">
                  {{ scoreFor(category, candidate) }}
                </td>
                <ng-container *ngIf="candidate != player">
                  <td class="vote">
                    <i class="material-icons upvote"
                       [class.voted]="votes[player][category][candidate] == 1"
                       (click)="upvote(candidate)">thumb_up</i>
                  </td>
                  <td  class="vote">
                    <i class="material-icons downvote"
                       [class.voted]="votes[player][category][candidate] == -1"
                       (click)="downvote(candidate)">thumb_down</i>
                  </td>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="true">
                <td class="duplicate word">
                  {{ judging[category][candidate] }}
                </td>
                <td class="score">
                  0
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
        </tr>
      </table>
      </div>
      <div class="center">
        <button (click)="next()" class="btn btn-flat next-category">
          Next Category →
        </button>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'finished'">
    <h1 class="header center">
      Scattergories
    </h1>

    <h5 class="center">
      Here's the scores so far
    </h5>

    <table class="scores">
      <tr *ngFor="let player of players">
        <th>{{ player }}</th>
        <td>{{ scores[player] }}</td>
      </tr>
    </table>

    <div class="center">
      <button class="waves-effect waves-light btn-large" (click)="start()">
        Play Another Round
      </button>
    </div>
  </div>
  <div *ngSwitchCase="'expired'">
    <expired game="scattergories" title="Scattergories"> </expired>
  </div>
  <div *ngSwitchCase="'full'">
    <full game="scattergories" title="Scattergories"> </full>
  </div>
</div>
