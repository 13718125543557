
<div class="section no-pad-bot" id="index-banner">
  <div class="container">
    <h1 class="header center">
      <img class="chair" src="assets/chair.gif">
      Word Play
    </h1>
    <div class="row center">
      <h2>
        Games to play on conference calls
      </h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="section">

    <div class="row">
      <div class="col s12 m6">
        <a href="boggle">
          <div class="game-description">
            <div id="boggle-screenshot"
                 class="screenshot-placeholder">

            </div>

            <h3>Boggle</h3>

            <p>
              Find words in sequences of adjacent letters on a randomly
              generated board of letters.
            </p>
          </div>
        </a>
      </div>

      <div class="col s12 m6">
        <a href="bananagrams">
          <div class="game-description">
            <div id="bananagrams-screenshot"
                 class="screenshot-placeholder">

            </div>

            <h3>Bananagrams</h3>

            <p>
              Complete against other players to form words from set of letter
              tiles as fast as possible.
            </p>
          </div>
        </a>
      </div>

      <div class="col s12 m6">
        <form ngNoForm action="api/yahtzee" method="POST">
          <a href="#" onclick="document.forms[0].submit(); return false;">
            <div class="game-description">
              <div id="yahtzee-screenshot"
                   class="screenshot-placeholder">

              </div>

              <h3>Yahtzee</h3>

              <p>
                Compete against other players (or the computer) to
                roll high scoring dice combinations.
              </p>
            </div>
          </a>
        </form>
      </div>

      <div class="col s12 m6">
        <form ngNoForm action="api/scattergories" method="POST">
          <a href="#" onclick="document.forms[1].submit(); return false;">
            <div class="game-description">
              <div id="scattergories-screenshot"
                   class="screenshot-placeholder">

              </div>

              <h3>Scattergories</h3>

              <p>
                Think of words beginning with a random letter within the time limit.
              </p>
            </div>
          </a>
        </form>
      </div>

    </div>
  </div>
</div>