import { Component } from '@angular/core';

@Component({
  selector:    'app-root',
  templateUrl: './app.html'
})

export class AppComponent {
  title = 'bored-games';
}
