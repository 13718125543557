<div class="home-link">
  <a href="/">
    <i class="material-icons">arrow_back</i>
    All Games
  </a>
</div>

<div [ngSwitch]="status">
  <div *ngSwitchCase="'initializing'">
    <div class="section">
      <h1 class="header center how-to">
        How to Play Yahtzee
      </h1>
    </div>

    <div class="section rules">
      <table>
        <tr class="rule-list-row">
          <td class="rule-list">
            <ul>
              <li>
                Each player takes a turn to roll 5 die aiming to roll various
                combinations. The active player can re-roll any of the dice up
                twice more, holding back a number of each time they re-roll. They
                must then declare for a combination in the scoreboard, and play
                proceeds to the next player.
              </li>
              <li>
                The top half of the scoreboard consists of single digit combinations.
                If, for instance, the player ends their turn with the the dice roll:
              </li>
              <li>
                <div class="center">
                  <span class="die die-3-1"> </span>
                  <span class="die die-3-3"> </span>
                  <span class="die die-3-3"> </span>
                  <span class="die die-3-1"> </span>
                  <span class="die die-4-1"> </span>
                </div>
              </li>
              <li>
                ...they can declares "Threes", which will score them 3 + 3 + 3 + = 12 points
                for that combination. The final die, not being a 3, is not counted.
              </li>
              <li>
                When a player fills out the top section, their scores for that section
                are totalled. If the total is 63 or more (equivalent to score three
                matching matching die in each combination), they receive a bonus of 35
                points.
              </li>
              <li>
                On any turn a player can declare a combination in the lower half of
                scoreboard too. This areas consists of harder combinations that each
                have specific score attached:
              </li>
              <li>
                <table class="combinations">
                  <tr>
                    <th>Combination</th>
                    <th>Description</th>
                    <th>Score</th>
                  </tr>
                  <tr>
                    <th>Three of Kind</th>
                    <td>Three or more die the same value</td>
                    <td>The sum of all the die rolled (including non-matching die)</td>
                  </tr>
                  <tr>
                    <th>Four of Kind</th>
                    <td>Four dice of the same value</td>
                    <td>The sum of all the die rolled (including non-matching die)</td>
                  </tr>
                  <tr>
                    <th>Full House</th>
                    <td>Three dice of one die two of another</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <th>Small Straight</th>
                    <td>Four die in consecutive order (e.g. 1,2,3,4)</td>
                    <td>35</td>
                  </tr>
                  <tr>
                    <th>Large Straight</th>
                    <td>Five die in consecutive order (e.g. 1,2,3,4,5)</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <th>Chance</th>
                    <td>Any dice! Handy when you get an unluck roll</td>
                    <td>Sum of all die</td>
                  </tr>
                  <tr>
                    <th>Yahtzee</th>
                    <td>Five matching die</td>
                    <td>50</td>
                  </tr>
                </table>
              </li>
              <li>
                Five matching die is a Yahtzee, which scores 50 points. Any
                subsequent Yahtzees score 100 points, and act as "jokers" - they
                can be used to declare a combination in the lower half of the
                scoreboard and claim that score.
              </li>
              <li>
                Note that a player is permitted to and will often declare a
                combination they don't have. They can claim "threes" even if
                they have no <span class="die die-3-1"> </span> in their final roll.
                This means they score 0 for that combination and play moves to
                next player.
              </li>
              <li>Each combination must be claimed exactly once by each player.
                The winner is player (or players) with the highest score when
                every player has taken 13 turns, and declared all combinations.
              </li>
            </ul>
          </td>
          <td>
            <div class="score-area">
              <div class="headers">
                <div class="scoring-spacer"></div>
                <div class="scoring-header"> Player </div>
              </div>
              <div class="scoring-row ones">
                <div class="score-label"> Ones </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row twos">
                <div class="score-label"> Twos </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row threes">
                <div class="score-label"> Threes </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row fours">
                <div class="score-label"> Fours </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row fives">
                <div class="score-label"> Fives </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row sixes">
                <div class="score-label"> Sixes </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row total">
                <div class="score-label"> Total </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row bonus">
                <div class="score-label"> Bonus </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row three-of-a-kind">
                <div class="score-label"> Three of a Kind </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row four-of-a-kind">
                <div class="score-label"> Four of a Kind </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row full-house">
                <div class="score-label"> Full House </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row small-straight">
                <div class="score-label"> Small Straight </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row large-straight">
                <div class="score-label"> Large Straight </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row chance">
                <div class="score-label"> Chance </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row yahtzee">
                <div class="score-label"> Yahtzee </div>
                <div class="score-value"> </div>
              </div>
              <div class="scoring-row grand-total">
                <div class="score-label"> Grand Total </div>
                <div class="score-value"> </div>
              </div>
            </div>
          </td>
        </tr>
      </table>

    </div>

    <a ngNoForm action="api/yahtzee" method="POST">
      <div class="container">
        <div class="section game-options">
          <div class="col s12 m12 center">
            <button class="waves-effect waves-light btn-large" type="submit">
              Got it!
            </button>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div *ngSwitchCase="'choosing'">
    <lobby game="yahtzee"
           title="Yahtzee"
           [room]="room"
           [currentPlayer]="player"
           [players]="players"
           [status]="status"
           computerPlayerOption="true"
           (starting)="start($event)"> </lobby>
  </div>
  <div *ngSwitchCase="'waiting'">
    <lobby game="yahtzee"
           title="Yahtzee"
           [room]="room"
           [currentPlayer]="player"
           [players]="players"
           [status]="status"
           computerPlayerOption="true"
           (starting)="start($event)"> </lobby>
  </div>
  <div *ngSwitchCase="'playing'">
    <div id="rules-modal" class="modal">
      <div class="modal-content">
        <div class="section">
          <h1 class="header center how-to">
            How to Play Yahtzee
          </h1>
        </div>

        <div class="section rules">
          <ul>
            <li>
              Each player takes a turn to roll 5 die aiming to roll various
              combinations. The active player can re-roll any of the dice up
              twice more, holding back a number of each time they re-roll. They
              must then declare for a combination in the scoreboard, and play
              proceeds to the next player.
            </li>
            <li>
              The top half of the scoreboard consists of single digit combinations.
              If, for instance, the player ends their turn with the the dice roll:
            </li>
            <li>
              <div class="center">
                <span class="die die-3-1"> </span>
                <span class="die die-3-3"> </span>
                <span class="die die-3-3"> </span>
                <span class="die die-3-1"> </span>
                <span class="die die-4-1"> </span>
              </div>
            </li>
            <li>
              ...they can declares "Threes", which will score them 3 + 3 + 3 + = 12 points
              for that combination. The final die, not being a 3, is not counted.
            </li>
            <li>
              When a player fills out the top section, their scores for that section
              are totalled. If the total is 63 or more (equivalent to score three
              matching matching die in each combination), they receive a bonus of 35
              points.
            </li>
            <li>
              On any turn a player can declare a combination in the lower half of
              scoreboard too. This areas consists of harder combinations that each
              have specific score attached:
            </li>
            <li>
              <table class="combinations">
                <tr>
                  <th>Combination</th>
                  <th>Description</th>
                  <th>Score</th>
                </tr>
                <tr>
                  <th>Three of Kind</th>
                  <td>Three or more die the same value</td>
                  <td>The sum of all the die rolled (including non-matching die)</td>
                </tr>
                <tr>
                  <th>Four of Kind</th>
                  <td>Four dice of the same value</td>
                  <td>The sum of all the die rolled (including non-matching die)</td>
                </tr>
                <tr>
                  <th>Full House</th>
                  <td>Three dice of one die two of another</td>
                  <td>25</td>
                </tr>
                <tr>
                  <th>Small Straight</th>
                  <td>Four die in consecutive order (e.g. 1,2,3,4)</td>
                  <td>35</td>
                </tr>
                <tr>
                  <th>Large Straight</th>
                  <td>Five die in consecutive order (e.g. 1,2,3,4,5)</td>
                  <td>40</td>
                </tr>
                <tr>
                  <th>Chance</th>
                  <td>Any dice! Handy when you get an unluck roll</td>
                  <td>Sum of all die</td>
                </tr>
                <tr>
                  <th>Yahtzee</th>
                  <td>Five matching die</td>
                  <td>50</td>
                </tr>
              </table>
            </li>
            <li>
              Five matching die is a Yahtzee, which scores 50 points. Any
              subsequent Yahtzees score 100 points, and act as "jokers" - they
              can be used to declare a combination in the lower half of the
              scoreboard and claim that score.
            </li>
            <li>
              Note that a player is permitted to and will often declare a
              combination they don't have. They can claim "threes" even if
              they have no <span class="die die-3-1"> </span> in their final roll.
              This means they score 0 for that combination and play moves to
              next player.
            </li>
            <li>Each combination must be claimed exactly once by each player.
              The winner is player (or players) with the highest score when
              every player has taken 13 turns, and declared all combinations.
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Got it</a>
      </div>
    </div>

    <div class="container">
      <h1 class="center">
        Yahtzee
        <a class="open-modal"
           (click)="showRules()">(rules)</a>
      </h1>
      <h5 class="center">
        {{ animateRoll && player == activePlayer ? 'Rolling...' : message }}
      </h5>
      <div class="section game-area">
        <table>
          <tr>
            <td>
              <div class="player-area">
                <div *ngFor="let participant of players"
                     player
                     [player]="participant"
                     [activePlayer]="activePlayer"
                     [currentPlayer]="player"
                     [rollNo]="rollNo"
                     [dice]="dice"
                     [held]="held"
                     [animateRoll]="animateRoll"
                     (rolling)="rolling($event)"> </div>
              </div>
            </td>
            <td>
              <div class="score-area">
                <div class="headers">
                  <div class="scoring-spacer"></div>

                  <div class="scoring-header" *ngFor="let participant of players">
                    {{ participant }}
                  </div>
                </div>

                <div yahtzee-score name="ones"            class="scoring-row ones"            label="Ones"            [row]="1"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="twos"            class="scoring-row twos"            label="Twos"            [row]="2"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="threes"          class="scoring-row threes"          label="Threes"          [row]="3"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="fours"           class="scoring-row fours"           label="Fours"           [row]="4"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="fives"           class="scoring-row fives"           label="Fives"           [row]="5"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="sixes"           class="scoring-row sixes"           label="Sixes"           [row]="6"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="total"           class="scoring-row total"           label="Total"           [row]="7"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer"> </div>
                <div yahtzee-score name="bonus"           class="scoring-row bonus"           label="Bonus"           [row]="8"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer"> </div>

                <div yahtzee-score name="three_of_a_kind" class="scoring-row three-of-a-kind" label="Three of a Kind" [row]="9"  [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="four_of_a_kind"  class="scoring-row four-of-a-kind"  label="Four of a Kind"  [row]="10" [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="full_house"      class="scoring-row full-house"      label="Full House"      [row]="11" [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="small_straight"  class="scoring-row small-straight"  label="Small Straight"  [row]="12" [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="large_straight"  class="scoring-row large-straight"  label="Large Straight"  [row]="13" [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="chance"          class="scoring-row chance"          label="Chance"          [row]="14" [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="yahtzee"         class="scoring-row yahtzee"         label="Yahtzee"         [row]="15" [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer" (declaring)="declaring($event)"> </div>
                <div yahtzee-score name="grand_total"     class="scoring-row grand-total"     label="Grand Total"     [row]="16" [context]="this" [scores]="scores" [potentialScores]="potentialScores" [animateRoll]="animateRoll" [activePlayer]="activePlayer"> </div>

              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'finished'">
    <div>
      <div class="container">
        <h1 class="header center">
          Yahtzee
        </h1>
        <h5 class="center">
          {{ message }}
        </h5>
        <div class="section game-area center">
          <div class="score-area">
            <div class="headers">
              <div class="scoring-spacer"></div>

              <div class="scoring-header" *ngFor="let participant of players">
                {{ participant }}
              </div>
            </div>

            <div yahtzee-score name="ones"            class="scoring-row ones"            label="Ones"            [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="twos"            class="scoring-row twos"            label="Twos"            [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="threes"          class="scoring-row threes"          label="Threes"          [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="fours"           class="scoring-row fours"           label="Fours"           [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="fives"           class="scoring-row fives"           label="Fives"           [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="sixes"           class="scoring-row sixes"           label="Sixes"           [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="total"           class="scoring-row total"           label="Total"           [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="bonus"           class="scoring-row bonus"           label="Bonus"           [context]="this" [scores]="scores"> </div>

            <div yahtzee-score name="three_of_a_kind" class="scoring-row three-of-a-kind" label="Three of a Kind" [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="four_of_a_kind"  class="scoring-row four-of-a-kind"  label="Four of a Kind"  [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="full_house"      class="scoring-row full-house"      label="Full House"      [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="small_straight"  class="scoring-row small-straight"  label="Small Straight"  [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="large_straight"  class="scoring-row large-straight"  label="Large Straight"  [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="chance"          class="scoring-row chance"          label="Chance"          [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="yahtzee"         class="scoring-row yahtzee"         label="Yahtzee"         [context]="this" [scores]="scores"> </div>
            <div yahtzee-score name="grand_total"     class="scoring-row grand-total"     label="Grand Total"     [context]="this" [scores]="scores"> </div>

          </div>

          <button class="waves-effect waves-light btn-large" (click)="start()">
            Play Again!
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'expired'">
    <expired game="yahtzee" title="Yahtzee"> </expired>
  </div>
  <div *ngSwitchCase="'full'">
    <full game="yahtzee" title="Yahtzee"> </full>
  </div>
</div>
