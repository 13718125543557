<div class="home-link">
  <a href="/">
    <i class="material-icons">arrow_back</i>
    All Games
  </a>
</div>

<div [ngSwitch]="status">
  <div *ngSwitchCase="'initializing'">
    <h1 class="header center how-to">
      How to Play Boggle
    </h1>

    <div class="section rules">
      <ul>
        <li>
          <div id="boggle-screenshot"></div>
        </li>
        <li>
          Find as many words in the letter grid as you can within the time limit.
        </li>
        <li>
          Words must be at least three letters long. The letters must be
          adjacent: either horizontally, vertically or diagonally. Each letter
          square can be used only once in a word.
        </li>
        <li>
          Words are scored according to their length: words of length 3 or 4
          score 1, words of length 5 score 2, words of length 6	score 3,
          words of length 7 score	5. Words of length 8 or more score 11.
          Incorrect guesses deduct 1 point from your total. If more than one
          team guesses the same word, it is eliminated from scoring.
        </li>
      </ul>
    </div>

    <form ngNoForm action="/api/boggle" method="POST" class="game-options">
      <h3>Game Options</h3>

      <h5>Number of Players</h5>

      <div class="row no-of-players">
        <label>
          <input name="players" type="radio" value="single" />
          <span>Single Player</span>
        </label>
      </div>

      <div class="row no-of-players">
        <label>
          <input name="players" type="radio" value="cooperative" checked />
          <span>Multiplayer (Cooperative)</span>
        </label>
      </div>

      <div class="row no-of-players">
        <label>
          <input name="players" type="radio" value="competitive" />
          <span>Multiplayer (Competitive)</span>
        </label>
      </div>

      <div class="row no-of-players">
        <label>
          <input name="players" type="radio" value="teams" />
          <span>Multiplayer (Teams)</span>
        </label>
      </div>

      <h5>Board Size</h5>

      <div class="row board-sizes">
        <div class="col m4 board-size">
          <label>
            <input name="size" type="radio" value="4" />
            <span>4 x 4</span>
          </label>
        </div>
        <div class="col m4 board-size selected">
          <label>
            <input name="size" type="radio" value="5" checked />
            <span>5 x 5</span>
          </label>
        </div>
        <div class="col m4 board-size">
          <label>
            <input name="size" type="radio" value="6" />
            <span>6 x 6</span>
          </label>
        </div>
      </div>

      <h5>Game Length</h5>

      <div class="row round-lengths">
        <div class="col m4 round-length">
          <label>
            <input name="round" type="radio" value="60" />
            <span>1 minute</span>
          </label>
        </div>
        <div class="col m4 round-length selected">
          <label>
            <input name="round" type="radio" value="120" checked />
            <span>2 minutes</span>
          </label>
        </div>
        <div class="col m4 round-length">
          <label>
            <input name="round" type="radio" value="180" />
            <span>3 minutes</span>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="center">
          <button class="waves-effect waves-light btn-large" type="submit">
            Get Started
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *ngSwitchCase="'choosing'">
    <div class="container">
      <h1 class="header center">
        Boggle
      </h1>

      <div class="section team-options">
        <div class="row">

          <div class="col m12 center">
            <div class="label">
              Pick a username:
            </div>

            <input type="text"
                   class="user-name"
                   maxlength="20"
                   [(ngModel)]="editedPlayerName"
                   (ngModelChange)="playerNameEdited($event)"
                   autofocus />

            <button class="waves-effect waves-light btn-flat"
                    (click)="acceptPlayerName()">Accept</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'waiting'">
    <div class="container">
      <h1 class="header center">
        Boggle
      </h1>

      <div class="section team-options">
        <div class="row">

          <div class="col m12 center">
            <div class="label">
              Pick a username:
            </div>

            <input type="text"
                   class="user-name"
                   maxlength="20"
                   [(ngModel)]="editedPlayerName"
                   (ngModelChange)="playerNameEdited($event)"
                   autofocus />

            <div *ngIf="players.length >= 1">
              <div class="label">
                Share this link with friends you want to play against:
              </div>

              <input type="text" class="share-link" value="{{ url }}" disabled />

              <button (click)="copyURL()" class="btn btn-small btn-flat">
                Copy
              </button>
            </div>

            <div *ngIf="mode == 'teams'">
              <div class="label">
                Pick a team:
              </div>

              <table id="players">
                <tr>
                  <th>
                    <img src="assets/office-plant.png" class="team-logo" />
                  </th>
                  <th>
                    <img src="assets/comfy-chair.png"  class="team-logo" />
                  </th>
                  <th>
                    <img src="assets/photocopier.png"  class="team-logo" />
                  </th>
                  <th>
                    <img src="assets/watercooler.png"  class="team-logo" />
                  </th>
                  <th>
                    <img src="assets/projector.png"    class="team-logo" />
                  </th>
                </tr>
                <tr>
                  <th class="team-name">
                    Team Office Plant
                  </th>
                  <th class="team-name">
                    Team Comfy Chair
                  </th>
                  <th class="team-name">
                    Team PC Load Letter
                  </th>
                  <th class="team-name">
                    Team Watercooler
                  </th>
                  <th class="team-name">
                    Team Device Not Found
                  </th>
                </tr>
                <tr class="player">
                  <td *ngFor="let team of predefinedTeamNames()" class="team-membership">
                    <div class="team-slider" *ngIf="teams[team].includes(player)">
                      {{ player }}
                    </div>
                    <div class="team-picker"
                         *ngIf="!teams[team].includes(player)"
                         (click)="changeTeam(player, team)">
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let participant of players" class="player">
                  <td *ngFor="let team of predefinedTeamNames()" class="team-membership">
                    <div class="team-slider" *ngIf="participant != player && teams[team].includes(participant)">
                      {{ participant }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <button class="waves-effect waves-light btn-large"
                    (click)="start()">Start!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'playing'">
    <div id="rules-modal" class="modal">
      <div class="modal-content">
        <div class="section">
          <h1 class="header center how-to">
            How to Play Boggle
          </h1>
        </div>

        <div class="section rules">
          <ul>
            <li>
              Find as many words in the letter grid as you can within the time limit.
            </li>
            <li>
              Words must be at least three letters long. The letters must be
              adjacent: either horizontally, vertically or diagonally. Each letter
              square can be used only once once in a word.
            </li>
            <li>
              Words are scored according to their length: words of length 3 or 4
              score 1, words of length 5 score 2, words of length 6	score 3,
              words of length 7 score	5. Words of length 8 or more score 11.
              Incorrect guesses deduct 1 point from your total. If more than one
              team guesses the same word, it is eliminated from scoring.
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Got it</a>
      </div>
    </div>

    <div class="container timed">
      <h1 class="header center">
        Boggle <a class="open-modal"
                  (click)="showRules()">(rules)</a>
      </h1>

      <div class="timer-holder">
        <timer [round]="round" [remaining]="remaining"> </timer>
      </div>

      <div class="row center">
        <h5 class="header col s12 light">
          Find as many words with three or more letters within the time limit!
        </h5>
      </div>
      <div class="section">
        <div class="row center">
          <div class="col m6 game-board">
            <div id="boggle-game-board">
              <div class="boggle-game-board-row"
                   *ngFor="let row of board; let y = index">
                <div class="boggle-game-board-square unflipped"
                     *ngFor="let letter of row; let x = index"
                     (click)="boardClicked($event, letter, x, y)">
                  <div [ngClass]="{ 'front' : true, 'selected' : this.selections[x][y] }">
                    {{ letter }}
                  </div>
                  <div class="back">

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col m6">
            <div class="actions">
              <button id="submit"
                      (click)="submitGuess()"
                      class="waves-effect btn-flat">Submit</button>
              <input id="word"
                     #word type="text"
                     placeholder="Start typing!"
                     [disabled]="submitting ? true : null"
                     [(ngModel)]="guess"
                     (keyup)="clearBoardSelection()"
                     (keyup.enter)="submitGuess()"
                     autofocus>
            </div>

            <div class="input-field col m12">
              <ul id="boggle-guesses">
                <li class="boggle-guess" *ngFor="let word of guesses">
                  <span [ngClass]="{ 'shake' : this.shaky.includes(word) }">
                    {{ word }}
                  </span>

                  <a class="delete-guess" (click)="deleteGuess(word)">
                    <i class="material-icons">clear</i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'finished'">
    <div class="container">
      <h1 class="header center">
        Boggle
      </h1>

      <div class="row center">
        <h5 class="header col s12 light">
          Here's your final score!
        </h5>
      </div>
      <div class="section">
        <div class="row center">
          <div class="col m6 game-board">
            <div id="boggle-game-board">
              <div class="boggle-game-board-row" *ngFor="let row of board">
                <div class="boggle-game-board-square" *ngFor="let letter of row">
                  <div class="front">
                    {{ letter }}
                  </div>
                </div>
              </div>
            </div>
            <button class="waves-effect waves-light btn-large restart-button" (click)="restart()">Play Again</button>
          </div>
          <div class="col m6 scores">
            <table id="boggle-scores">
              <tr *ngIf="teamNames().length > 1" class="team-logos">
                <th></th>
                <th *ngFor="let team of teamNames()">
                  <img src="assets/office-plant.png" class="team-logo" *ngIf="team == 'Team Office Plant'"     />
                  <img src="assets/comfy-chair.png"  class="team-logo" *ngIf="team == 'Team Comfy Chair'"      />
                  <img src="assets/photocopier.png"  class="team-logo" *ngIf="team == 'Team PC Load Letter'"   />
                  <img src="assets/watercooler.png"  class="team-logo" *ngIf="team == 'Team Watercooler'"      />
                  <img src="assets/projector.png"    class="team-logo" *ngIf="team == 'Team Device Not Found'" />
                </th>
              </tr>
              <tr *ngIf="teamNames().length > 1" class="team-names">
                <th></th>
                <th *ngFor="let team of teamNames()" class="team-name">
                  {{ team }}
                </th>
              </tr>
              <tr class="solution" *ngFor="let guess of allGuesses()">
                <td class="word">{{ guess }}</td>
                <td *ngFor="let team of teamNames()" class="score">
                  {{ scores['words'][guess][team] }}
                </td>
              </tr>
              <tr class="total-row">
                <th class="word">SCORE</th>
                <th *ngFor="let team of teamNames()" class="score">
                  <span class="total">{{ scores['scores'][team] || '0' }}</span>
                </th>
              </tr>
            </table>
          </div>
        </div>
        <div id="boggle-solutions-title">
          <h5>Full Word List</h5>
        </div>
        <div id="boggle-solutions">
          <span class="solution" *ngFor="let solution of solutions">
            {{ solution }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'expired'">
    <expired game="boggle" title="Boggle"> </expired>
  </div>
  <div *ngSwitchCase="'full'">
    <full game="boggle" title="Boggle"> </full>
  </div>
</div>
