<span class="timer">
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle class="clock-circle"     cx="50" cy="50" r="50" fill="black"></circle>
      <circle class="clock-background" cx="50" cy="50" r="40" fill="white"></circle>
      <path #progress
            d="M 50 50 L 50 -10 A 60 60 0 1 0 50 -10"
            fill="white"
            stroke-width="0"
            fill-opacity="1.0"/>
    </g>
  </svg>
  <span class="remaining">{{ formattedTime }}</span>
</span>
