<div class="score-label">
  {{ label }}
</div>

<div class="score-value" *ngFor="let player of players">
  <div *ngIf="scores[player][name] == null &&
              player == activePlayer       &&
              player == currentPlayer      &&
              !animateRoll"
       [class.declare-score]="true"
       [class.choice]="!!potentialScores[name]"
       [class.zero]="!potentialScores[name]"
       [ngClass]="rowClass"
       (click)="declare()">
    {{ animateRoll ? '' : (potentialScores[name])}}
  </div>

  <div class="current-score" *ngIf="scores[player][name] !== null">
    {{ name == 'yahtzee'           &&
       potentialScores             &&
       potentialScores[name]       &&
       player == activePlayer      &&
       player == currentPlayer     &&
       !animateRoll                &&
       potentialScores[name] > scores[player][name] ?
          potentialScores[name] : scores[player][name] }}
  </div>
</div>