import * as io from 'socket.io-client';
import { toast, Modal } from 'materialize-css'
import { ActivatedRoute } from '@angular/router'
import { Component, OnInit, ElementRef, } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector:    'boggle',
  templateUrl: './boggle.html',
  styleUrls:   ['./boggle.less']
})
@Injectable()
export class BoggleComponent implements OnInit {

  room:          string      // The name of the current game room we are in, taken from the URL.
  teams:         object      // All teams in the current game room.
  players:       string[]    // All players in the current game room.

  team:          string      // The team name for the current player.
  player:        string      // The user name of the player.

  size:          number      // The board size (e.g. 5), stored so we regenerate the board when restarting.
  round:         number      // The round length in seconds.
  remaining:     number      // The number of seconds remaining in this round (calculated).

  status     = 'initializing' // Game state: 'initializing'/'waiting'/'loading'/'playing'/'finished'/'expired'
  submitting = false          // Flag to indicate the user is submitting a guess to the API.

  board:         string[][]  // The generated Boggle board, a square array of characters.
  selections:    boolean[][] // Board squares the player has recently clicked on.
  lastSelectedX = -10        // Last selected board square X coordinate.
  lastSelectedY = -10        // Last selected board square Y coordinate.

  guess      = ''            // Text of the current guess, as typed in by the player.
  guesses    = []            // All guesses by the user's team.

  countingDown = false       // Flag to indicate the countdown has started.

  scores:     {}             // When the round completes, the scores per team.
  solutions:  string[]       // When the round completes, the full word list.

  socket: any                // SocketIO connection to server.

  url: string                // Current URL, for sharing with other players.

  editedPlayerName: string   // Edits to the player name.

  // We wait for a second of inactivity before syncing the player name.
  playerNameEdit = new Subject<string>()

  shaky = []                  // Elements we want to add a shake animation to.

  mode: string                // 'single'/'cooperative'/'competitive'/'teams'

  constructor(private page:   ElementRef,
              private http:   HttpClient,
              private route:  ActivatedRoute) {
    this.url = window.location.href
  }

  ngOnInit(): void {
    this.room = this.route.snapshot.params["room"]

    if (this.room) {
      this.loadGameState()
    }
    else {
      this.status = 'initializing'
    }

    this.playerNameEdit.pipe(
      debounceTime(1000)
    )
    .subscribe(() => {
      this.renamePlayer()
    })
  }

  loadGameState() {
    this.status = 'loading'

    this.http.get('/api/boggle/' + this.room)
        .subscribe((data) => {
          this.status = data['status']

          if (this.status == 'expired') {
            return
          }

          this.board            = data['board']
          this.size             = data['size']
          this.round            = data['round']
          this.remaining        = data['remaining'] || data['round']

          this.mode             = data['mode']
          this.team             = data['team']
          this.teams            = data['teams']
          this.player           = data['player']
          this.editedPlayerName = data['player']
          this.players          = data['players']

          if (this.team) {
            this.guesses = data['guesses'][this.team] || []
          }
          else {
            this.guesses = []
          }

          if (this.status == 'finished') {
            this.scores    = data['scores']
            this.solutions = data['solutions']
          }
          else {
            this.scores    = {}
            this.solutions = []
          }

          this.clearBoardSelection()
          this.subscribeForUpdates()

          if (this.status == 'playing') {
            this.startCountdown()
          }
        })
  }

  playerNameEdited() {
    this.playerNameEdit.next()
  }

  renamePlayer() {
    this.http.post('/api/boggle/' + this.room + '/players', { player: this.editedPlayerName }).subscribe((data) => {
      this.player  = data['player']
      this.team    = data['team']
      this.players = data['players']
      this.teams   = data['teams']
    })
  }

  acceptPlayerName() {
    this.renamePlayer()
    this.status = 'waiting'
  }

  changeTeam(player, team) {
    this.http.post('/api/boggle/' + this.room + '/teams', { player: player, team: team }).subscribe((ars) => {
      this.player = player
      this.team   = team
    })
  }

  copyURL() {
    const url = window.location.href

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', url)
      e.preventDefault()
      document.removeEventListener('copy', null)
    })

    document.execCommand('copy')
  }

  startCountdown() {
    if (!this.countingDown) {
      setInterval(() => {
        if (this.remaining <= 1) {
          if (this.status == 'playing') {
            this.finish()
          }
        }
        else {
          this.remaining -= 1
        }
      }, 1000)

      this.countingDown = true
    }
  }

  subscribeForUpdates() {
    this.socket = io('/boggle')

    this.socket.emit('join', this.room, this.team, this.player)

    this.socket.on('joined',    (data)      => { this.joined(data)       })
    this.socket.on('changed',   (data)      => { this.changed(data)      })
    this.socket.on('started',   (remaining) => { this.started(remaining) })
    this.socket.on('update',    (guesses)   => { this.updated(guesses)   })
    this.socket.on('finished',  (results)   => { this.finished(results)  })
    this.socket.on('restarted', ()          => { this.restarted()        })
  }

  joined(data) {
    if (data.player != this.player && !this.players.includes(data.player)) {
      const players = this.teams[data.team]

      this.players.push(data.player)

      if (!players) {
        this.teams[data.team] = [ data.player ]

        toast({ html: data.player + ' joined the game!', classes: 'friendly-toast' })
      }
      else if (!players.includes(data.player)) {
        players.push(data.player)

        toast({ html: data.player + ' joined the game!', classes: 'friendly-toast' })
      }
    }
  }

  changed(data) {
    console.log("Updating teams and players: " + data)

    this.players = data.players
    this.teams   = data.teams
  }

  start() {
    this.socket.emit('start', this.room, this.player)
  }

  started(remaining) {
    this.status    = 'playing'
    this.remaining = parseInt(remaining)
    this.guesses   = []

    this.focusGuessInputBox()
    this.startCountdown()
  }

  updated(guesses) {
    this.guesses = guesses[this.team]

    if (this.submitting) {
      this.submitting = false

      this.focusGuessInputBox()
    }
  }

  finish() {
    this.socket.emit('finish', this.room)
  }

  finished(results) {
    this.status    = 'finished'
    this.teams     = results.teams
    this.scores    = results.scores
    this.solutions = results.solutions
  }

  restart() {
    this.socket.emit('restart', this.room)
  }

  restarted() {
    this.guess = ''
    this.loadGameState()
  }

  showRules() {
    const elem = document.getElementById('rules-modal')

    Modal.init(elem).open()
  }

  submitGuess() {
    this.guess = this.guess.replace(/[^a-zA-Z]/g, '').toUpperCase()

    if (this.guess.length > 2) {
      if (this.guesses.includes(this.guess)) {
        this.shaky = [ this.guess ]

        setTimeout(() => {
          this.shaky = []
        }, 200)
      }
      else {
        this.submitting = true
        this.socket.emit('guess', this.guess, this.room, this.team, this.player)
      }

      this.guess = ''
      this.focusGuessInputBox()
      this.clearBoardSelection()
    }
  }

  deleteGuess(word) {
    this.submitting = true
    this.socket.emit('delete', word, this.room, this.team, this.player)
    this.focusGuessInputBox()
  }

  boardClicked(event, letter, x, y) {
    if (this.selections[x][y]) {
      if (this.lastSelectedX == x && this.lastSelectedY == y) {
        this.selections[x][y] = false

        this.guess.slice(0, -1)
      }
      else {
        this.clearBoardSelection()

        this.selections[x][y] = true

        this.guess = letter.toLowerCase()
      }
    }
    else {
      if (Math.abs(x - this.lastSelectedX) <= 1 && Math.abs(y - this.lastSelectedY) <= 1) {
        this.guess += letter.toLowerCase()

        this.selections[x][y] = true
      }
      else {
        this.clearBoardSelection()

        this.selections[x][y] = true

        this.guess = letter.toLowerCase()
      }
    }

    this.lastSelectedX = x
    this.lastSelectedY = y

    this.focusGuessInputBox()
  }

  focusGuessInputBox() {
    setTimeout(() => {
      this.page.nativeElement.querySelector('#word').focus()
    })
  }

  clearBoardSelection() {
    const selections = []

    for (let x = 0; x < this.size; x++) {
      selections.push(new Array(this.size).fill(false))
    }

    this.selections = selections
  }

  teamNames() {
    return Object.keys(this.teams).sort()
  }

  predefinedTeamNames() {
    return [
      "Team Office Plant",
      "Team Comfy Chair",
      "Team PC Load Letter",
      "Team Watercooler",
      "Team Device Not Found"
    ]
  }

  allGuesses(team) {
    if (!this.scores || !this.scores['words']) {
      return []
    }

    const guesses = Object.keys(this.scores['words'])
    return guesses.sort((first, second) => {
      if (first.length == second.length) {
        if (first == second) {
          return 0
        }

        return first < second ? -1 : 1
      }

      return first.length < second.length ? -1 : 1
    })
  }
}
