import * as io from 'socket.io-client'
import { toast, Modal } from 'materialize-css'
import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Component({
  selector: 'yahtzee',
  templateUrl: './yahtzee.html',
  styleUrls: ['./yahtzee.less']
})
@Injectable()
export class YahtzeeComponent implements OnInit {

  room:            string     // The name of the current game room we are in, taken from the URL.
  player:          string     // The user name of the player whose browser we are in.
  players:         string[]   // All players.
  activePlayer:    string     // The player currently rolling.
  scores:          any        // Current scoring for all players.
  potentialScores: {}         // Indicative scoring for the active player.
  rollNo:          number     // Roll number (0-3).
  dice:            number[]   // Last set of die rolled by the active player.
  held:            boolean[]  // Dice held back from the previous roll.
  url:             string     // Current URL, for sharing other players before the game starts.
  winners:         string[]   // Who won the game.

  message:         string     // Status message on the top of the screen.

  status  = 'loading'         // Game state: 'loading'/'initializing'/'waiting'/'playing'/'finished'/'expired'

  socket: any                 // SocketIO connection to server.

  animateRoll = false         // Whether we should be animate the dice rolling right now.

  constructor(private http:  HttpClient,
              private route: ActivatedRoute) {
    this.url = window.location.href
  }

  ngOnInit(): void {
    this.room = this.route.snapshot.params['room']

    if (this.room) {
      this.loadGameState()
    }
    else {
      this.status = 'initializing'
    }
  }

  loadGameState() {
    console.log("Loading game state")

    this.http.get('/api/yahtzee/' + this.room)
        .subscribe((data) => {
          this.status = data['status']

          if (this.status == 'expired') {
            return
          }

          this.socket = io('/yahtzee')

          this.player           = data['player']
          this.players          = data['players']
          this.activePlayer     = data['active_player']
          this.rollNo           = data['roll_no']
          this.dice             = data['dice']
          this.held             = data['held']
          this.scores           = data['scores']
          this.potentialScores  = data['potential_scores'] || {}
          this.winners          = data['winners']

          this.generateMessage()

          this.subscribeForUpdates()
        })
  }

  generateMessage() {
    if (this.status == 'finished') {
      this.message = this.winners.join(', ') + " won!"
      return
    }

    const remaining = 3 - this.rollNo

    if (this.player == this.activePlayer) {
      if (remaining == 3) {
        this.message = "Your turn! Click 'Roll' to start"
      }
      else if (remaining == 0) {
        this.message = "Pick a combination to declare"
      }
      else {
        this.message = "Choose which dice to hold, or pick a combination"
      }
    }
    else {
      if (remaining == 0) {
        this.message = this.activePlayer + " has no more rolls"
      }
      else if (this.activePlayer != null) {
        this.message = this.activePlayer + " is playing"
      }
      else {
        this.message = 'Calculating winner...'
      }
    }
  }

  subscribeForUpdates() {
    console.log("Subscribing for updates")

    this.socket.emit('join', this.room)

    this.socket.on('started',   (data) => { this.started(data)      })
    this.socket.on('joined',    (data) => { this.joined(data)       })
    this.socket.on('changed',   (data) => { this.changed(data)      })
    this.socket.on('rolled',    (data) => { this.rolled(data)       })
    this.socket.on('declared',  (data) => { this.declared(data)     })
    this.socket.on('computer',  (data) => { this.computerTurn(data) })
    this.socket.on('finished',  (data) => { this.finished(data)     })
  }

  joined(data) {
    if (data.player != this.player && !this.players.includes(data.player)) {
      this.players.push(data.player)

      toast({ html: data.player + ' joined the game!', classes: 'friendly-toast' })
    }
  }

  changed(data) {
    console.log("Players updated: [" + data.players + "], rename is: " + data.renamed + " current player is " + this.player)

    this.players = data.players

    if (data.renamed) {
      if (data.renamed.old == this.player || !this.players.includes(this.player)) {
        this.player = data.renamed.new || this.player
      }
    }
  }

  start(data) {
    console.log("Starting game...")

    this.socket.emit('start', this.room, this.player, data ? data.addComputerPlayer : false)
  }

  started(data) {
    console.log("...started game, players are: " + data.players + " first to go is: " + data.active_player)

    this.players         = data.players
    this.activePlayer    = data.active_player

    this.status          = 'playing'
    this.rollNo          = 0
    this.dice            = [ 1, 2, 3, 4, 5 ]
    this.held            = [ false, false, false, false, false ]
    this.scores          = data.scores
    this.potentialScores = {}

    this.generateMessage()
  }

  rolling(data) {
    console.log("Rolling dice, holding: " + this.held)

    this.socket.emit('roll', data.dice, data.held, this.player, this.room)
  }

  rolled(data) {
    console.log("...rolled dice, received the following: " + data.dice)

    this.animateRoll     = true
    this.dice            = data.dice
    this.held            = data.held || this.held
    this.potentialScores = data.potential_scores || {}
    this.rollNo          = this.rollNo + 1

    this.generateMessage()

    const parent = this

    setTimeout(() => {
      parent.animateRoll = false
    }, 1200)
  }

  declaring(name) {
    console.log('Declaring: ' + name)

    this.socket.emit('declare', this.dice, name, this.player, this.room)
  }

  declared(data) {
    console.log('...declared. Next player is: ' + data.active_player)

    this.animateRoll     = false
    this.activePlayer    = data.active_player
    this.scores          = data.scores
    this.held            = [ false, false, false, false, false ]
    this.rollNo          = 0
    this.potentialScores = {}

    this.generateMessage()
  }

  computerTurn(data) {
    console.log("Simulating the computer's turn")

    const rolls  = data.rolls
    const next   = data.next
    const scores = data.scores
    const parent = this

    setTimeout(() => {
      parent.rolled({ dice: rolls[0].dice })
    }, 1000)

    if (rolls.length > 1) {
      setTimeout(() => {
        parent.rolled({dice: rolls[1].dice, held: rolls[1].held})
      }, 3000)
    }

    if (rolls.length > 2) {
      setTimeout(() => {
        parent.rolled({dice: rolls[2].dice, held: rolls[2].held})
      }, 5000)
    }

    setTimeout(() => {
      parent.declared({ active_player: next, scores: scores })
    }, 1000 + rolls.length * 2000)
  }

  finished(data) {
    const parent = this

    setTimeout(() => {
      parent.status  = 'finished'

      parent.winners  = data.winners
      parent.scores   = data.scores

      parent.generateMessage()
    }, this.activePlayer == 'Computer Bob' ? 8000 : 1200)
  }

  showRules() {
    const elem = document.getElementById('rules-modal')

    Modal.init(elem).open()
  }
}
