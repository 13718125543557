import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core'
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

@Component({
    selector: '[player]',
    templateUrl: './player.html',
    styleUrls: ['./player.less'],
    animations: [
       trigger('activating', [
          state('inactive', style({
              height: '80px',
              borderColor: '#eee',
              boxShadow: '3px 3px 1px #ddd'
          })),
          state('active', style({
              height: '380px',
              borderColor: '#cce',
              boxShadow: '3px 3px 2px #333'
          })),
          transition('active => inactive', [
              animate('0.4s')
          ]),
          transition('inactive => active', [
              animate('0.4s')
          ])
       ])
    ]
})
export class YahtzeePlayerComponent implements OnChanges {

    @Input() player:        string    // The player represented by this component.
    @Input() activePlayer:  string    // The player actively rolling right now.
    @Input() currentPlayer: string    // The player whose browser we are in.
    @Input() dice:          number[]  // The set of die last rolled by the active player.
    @Input() held:          boolean[] // Flags indicated die held back from the last roll by active player.
    @Input() rollNo:        number    // The roll number (0-3)
    @Input() animateRoll:   boolean   // Whether we should be animate the dice rolling right now.

    rollingDie:             boolean   // Whether the die are rolling.
    diceClasses:            string[]  // CSS classes representing what dice the user has rolled.

    heldDie:                string[]  // Ordered collection of held die.

    message:                string    // "How many rolls remaining" message.

    @Output() rolling = new EventEmitter()

    constructor() {
        this.diceClasses = [ 'pre-roll', 'pre-roll', 'pre-roll', 'pre-roll', 'pre-roll' ]
    }

    ngOnChanges(): void {
        this.rollingDie = this.animateRoll

        if (this.rollNo == 0) {
            if (this.activePlayer == this.currentPlayer) {
                this.message = "Click 'Roll' to start"
            }
            else {
                this.message = "3 rolls remaining"
            }
        }
        else if (this.rollNo == 1) {
            this.message = "2 rolls remaining"
        }
        else if (this.rollNo == 2) {
            this.message = "1 roll remaining"
        }
        else if (this.rollNo == 3) {
            this.message = "No more rolls"
        }

        for (let i = 0; i < this.dice.length; i++) {
            const die = this.dice[i]

            if (die === 0) {
                this.diceClasses[i] = 'pre-roll'
            }
            else {
                if (this.diceClasses[i] == 'pre-roll' || !this.held[i]) {
                    if (this.animateRoll) {
                        this.animateDieRoll(i, die)
                    }
                    else {
                        this.diceClasses[i] = 'die die-' + die + '-1 die-no-' + i
                    }
                }
            }
        }

        this.recalculateHeldDie()
    }

    animateDieRoll(index, die) {
        let interval = 100
        let frames   = 12

        const diceClasses = this.diceClasses

        for (let frameNo = 0; frameNo < frames; frameNo++) {
            setTimeout(function () {
                diceClasses[index] = 'die die-' + getRandomInt(1, 4) + '-' + getRandomInt(1, 4)
            }, frameNo * interval)
        }
    }

    hold(dieNo) {
        this.held[dieNo] = true
        this.recalculateHeldDie()
    }

    release(dieNo) {
        const elements = this.heldDie[dieNo].split('-')
        const index = parseInt(elements[elements.length - 1])

        this.held[index] = false
        this.recalculateHeldDie()
    }

    recalculateHeldDie() {
        const held = []

        for (let i = 0; i < this.dice.length; i++) {
            if (this.held[i]) {
                held.push(this.diceClasses[i])
            }
        }

        held.sort()

        this.heldDie = held
    }

    roll() {
        this.rolling.emit({ dice: this.dice, held: this.held })
    }
}

function getRandomInt(min, max) {
    return min + Math.floor(Math.random() * Math.floor(max));
}