import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core'

@Component({
    selector: '[yahtzee-score]',
    templateUrl: './score.html',
    styleUrls: ['./score.less']
})
export class YahtzeeScoreComponent implements OnInit {

    @Input() name:             string   // The name of this scoring row (e.g. 'small_straight')
    @Input() label:            string   // The label for this row (e.g. 'Small Straight')
    @Input() activePlayer:     string   // The player actively rolling right now.
    @Input() scores:           any      // Current scoring for all players.
    @Input() potentialScores:  any      // Indicative scoring for the active player.
    @Input() context:          any      // Parent object

    @Input() row         = 0            // Vertical index on page (e.g 'ones' is 0, 'twos' is 1)
    @Input() animateRoll =     false    // We don't show the potential scores until the die has finished rolling.

    players:           string[] // All players
    currentPlayer:     string   // The player whose browser we are in.
    rowClass:          string

    @Output() declaring = new EventEmitter<string>()

    constructor() {}

    ngOnInit(){
        this.players         = this.context.players
        this.currentPlayer   = this.context.player
        this.rowClass        = 'row-' + this.row
    }

    declare() {
        this.declaring.emit(this.name)
    }
}
