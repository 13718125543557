<div class="home-link">
  <a href="/">
    <i class="material-icons">arrow_back</i>
    All Games
  </a>
</div>

<div [ngSwitch]="status">
  <div *ngSwitchCase="'initializing'">
    <h1 class="header center how-to">
      How to Play Bananagrams
    </h1>

    <div class="section rules">
      <ul>
        <li>
          <div id="bananagrams-screenshot"></div>
        </li>
        <li>
          Place your letter tiles on the board to make words vertically or
          horizontally.
        </li>
        <li>
          When you use up all your letters, click <strong>PEEL</strong> to get a new letter
          tile. This add a letter tile for all players. At any point, you can dump a difficult
          letter tile - but you will get 3 replacement letters in return!
        </li>
        <li>Once all letter tiles have been dealt, the winner is the first player to use their
          letters in valid words.
        </li>
      </ul>
    </div>

    <div class="center">
      <form ngNoForm action="api/bananagrams" method="POST">
        <button class="waves-effect waves-light btn-large" type="submit">
          Got it!
        </button>
      </form>
    </div>
  </div>
  <div *ngSwitchCase="'waiting'">
    <lobby game="bananagrams"
           title="Bananagrams"
           [currentPlayer]="player"
           [players]="players"
           [status]="status"
           [room]="room"
           (starting)="start($event)"> </lobby>
  </div>
  <div *ngSwitchCase="'playing'">
    <div id="rules-modal" class="modal">
      <div class="modal-content">
        <div class="section">
          <h1 class="header center how-to">
            How to Play Bananagrams
          </h1>
        </div>

        <div class="section rules">
          <ul>
            <li>
              Place your letter tiles on the board to make words vertically or
              horizontally.
            </li>
            <li>
              When you use up all your letters, click <strong>PEEL</strong> to get a new letter
              tile. This add a letter tile for all players. At any point, you can dump a difficult
              letter tile - but you will get 3 replacement letters in return!
            </li>
            <li>Once all letter tiles have been dealt, the winner is the first player to use their
              letters in valid words.
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Got it</a>
      </div>
    </div>

    <div [ngClass]="{ 'container' : true, 'smallify' : smallify }">
      <h1 class="center">
        Bananagrams
        <a class="open-modal"
           (click)="showRules()">(rules)</a>
      </h1>
      <h5 class="center">
        Place your tiles on the board to make interconnected words
      </h5>
      <div class="game-area" cdkDropListGroup>
        <div class="row">
          <div class="game-board">
              <div class="game-board-row" *ngFor="let row of board; let rowNo = index">
                <div *ngFor="let letter of row; let colNo = index"
                     class="game-board-square"
                     [ngClass]="{ 'game-board-square' : true, 'valid' : valid[rowNo][colNo] , 'invalid' : !valid[rowNo][colNo] }"
                     cdkDropList
                     (cdkDropListDropped)="dropped($event.item.data, board, rowNo, colNo)">

                  <div class="letter"
                       cdkDrag
                       [cdkDragData]="{ source: board, rowNo: rowNo, colNo: colNo }"
                       *ngIf="letter">

                    {{ letter }}

                    <div class="drag-placeholder" *cdkDragPlaceholder> </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="row">
          <div class="reserve">
            <div class="reserved-row" *ngFor="let row of reserve; let rowNo = index">
              <div *ngFor="let letter of row; let colNo = index"
                   class="reserve-square"
                   cdkDropList
                   (cdkDropListDropped)="dropped($event.item.data, reserve, rowNo, colNo)">

                <div class="letter"
                     cdkDrag
                     [cdkDragData]="{ source: reserve, rowNo: rowNo, colNo: colNo }"
                     *ngIf="letter">

                  {{ letter }}

                  <div class="drag-placeholder" *cdkDragPlaceholder> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="actions">
            <div class="action-items" *ngIf="!exhaustedLetters">
              <span class="dump"
                    cdkDropList
                    (cdkDropListDropped)="dumping($event.item.data)">
                Drag a tile here to dump it
              </span>
              <button class="waves-effect btn-large" (click)="shuffleReserve()">
                Shuffle
              </button>
              <button class="waves-effect btn-large" (click)="peel()">
                Peel
              </button>
            </div>
            <div class="action-items" *ngIf="exhaustedLetters">
              <span class="dump">
                No more letter tiles!
              </span>
              <button class="waves-effect btn-large" (click)="shuffleReserve()">
                Shuffle
              </button>
              <button class="waves-effect btn-large disabled">
                Peel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'finished'">
    <div>
      <div class="container smallify">
        <h1 class="header center">
          Bananagrams
        </h1>
        <div class="row center">
          <h2 class="header col s12 light">
            {{ this.winner == this.player ? "You won!" : this.winner + " won" }}
          </h2>
        </div>

        <div class="section">
          <div class="row center" *ngFor="let participant of players">
            <div class="col m12">
              <h4 *ngIf="players.length > 1">{{ participant == this.player ? 'Your Board' : participant + "'s Board" }}</h4>
              <div class="game-board">
                <div class="game-board-row"
                     *ngFor="let row of boards[participant].board; let rowNo = index">
                  <div class="game-board-square valid"
                       *ngFor="let letter of row; let colNo = index">
                    <div class="letter" *ngIf="letter">
                      {{ letter }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="leftovers" *ngIf="boards[participant].remaining.length > 0">
                <span class="label">
                  Tiles remaining:
                </span>
                <span class="letters">
                  {{ boards[participant].remaining }}
                </span>
              </div>

            </div>
          </div>

          <div class="row center">
            <div class="col m12">
              <button class="waves-effect waves-light btn-large" (click)="start()">
                Play Again!
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div *ngSwitchCase="'expired'">
    <expired game="bananagrams" title="Bananagrams"> </expired>
  </div>
  <div *ngSwitchCase="'full'">
    <full game="bananagrams" title="Bananagrams"> </full>
  </div>
</div>
