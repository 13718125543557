import { NgModule               } from '@angular/core';
import { Routes, RouterModule   } from '@angular/router';
import { HomeComponent          } from "./home/home";
import { BoggleComponent        } from "./boggle/boggle";
import { CodewordsComponent     } from "./codewords/codewords";
import { ScattergoriesComponent } from "./scattergories/scattergories";
import { BananagramsComponent   } from './bananagrams/bananagrams';
import { YahtzeeComponent       } from './yahtzee/yahtzee';

const routes: Routes = [
  { path: '',                    component: HomeComponent          },
  { path: 'boggle/:room',        component: BoggleComponent        },
  { path: 'boggle',              component: BoggleComponent        },
  { path: 'bananagrams/:room',   component: BananagramsComponent   },
  { path: 'bananagrams',         component: BananagramsComponent   },
  { path: 'yahtzee/:room',       component: YahtzeeComponent       },
  { path: 'yahtzee',             component: YahtzeeComponent       },
  { path: 'codewords/:room',     component: CodewordsComponent     },
  { path: 'codewords',           component: CodewordsComponent     },
  { path: 'scattergories/:room', component: ScattergoriesComponent },
  { path: 'scattergories',       component: ScattergoriesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
