<div class="player" [@activating]="player == activePlayer ? 'active' : 'inactive'" >
  <div class="player-name">
    {{ player  }}
  </div>

  <div *ngIf="player == activePlayer">
    <div class="player-message">
      {{ rollingDie ? 'Rolling...' : message }}
    </div>

    <div class="rolled-die" *ngIf="player == currentPlayer">
      <div class="die-container" *ngFor="let die of dice; let dieNo = index">
        <div *ngIf="!held[dieNo]" (click)="0 < rollNo && rollNo < 3 && hold(dieNo)" class="held">
          <div [ngClass]="diceClasses[dieNo]"> </div>
        </div>
      </div>
    </div>

    <div class="well" *ngIf="player == currentPlayer">
      <div class="die-container" *ngFor="let die of heldDie; let dieNo = index">
        <div (click)="rollNo < 3 && release(dieNo)" class="not-held">
          <div [ngClass]="heldDie[dieNo]"> </div>
        </div>
      </div>
    </div>

    <div class="actions" *ngIf="player == currentPlayer">
      <a class="btn btn-flat" *ngIf="rollNo < 3" (click)="roll()">
        Roll
      </a>

      <div class="prompt" *ngIf="rollNo == 3">
        Pick a Combination →
      </div>
    </div>

    <div class="rolled-die" *ngIf="player != currentPlayer">
      <div class="die-container" *ngFor="let die of dice; let dieNo = index">
        <div>
          <div [ngClass]="diceClasses[dieNo]"> </div>
        </div>
      </div>
    </div>
  </div>
</div>
