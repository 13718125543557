<h1 class="header center">
  {{ title }}
</h1>

<h5 class="header center">
  This game has timed out. Want to start again?
</h5>

<div class="center">
  <a href="/{{ game }}" class="waves-effect waves-light btn-large">
    Start Playing!
  </a>
</div>
