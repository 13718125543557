import {Component, Input, OnInit, EventEmitter, Output } from '@angular/core'
import { Subject                                       } from "rxjs"
import { debounceTime                                  } from "rxjs/operators"
import { HttpClient                                    } from "@angular/common/http"

@Component({
  selector: 'lobby',
  templateUrl: './lobby.html',
  styleUrls: ['./lobby.less']
})
export class LobbyComponent implements OnInit {

  /**
   * A component that allows for a URL to be shared and have players join
   * a game before it starts.
   */

  @Input() game:           string   // The name of the game (e.g. 'bananagrams') used in constructing URLs.
  @Input() title:          string   // The page title (e.g. Bananagrams)
  @Input() room:           string   // The game room name, randomly constructed
  @Input() currentPlayer:  string   // The username of the player whose browser we are in
  @Input() players:        string[] // Full list of players.
  @Input() status:         string   // The current status, expected to be 'choosing' (choosing
                                    // an initial player name) or 'waiting' (waiting for other
                                    // players to join.

  // Whether we should add a checkbox allowing creation of a computer player.
  @Input() computerPlayerOption = false

  // The state for that checkbox.
  addComputerPlayer = false

  // The current URL, for sharing.
  url: string

  // Wait for a second of inactivity before syncing the player name.
  playerNameEdit = new Subject<string>()

  // When all players are present, any player can click start and we emit the start event.
  @Output() starting = new EventEmitter()

  constructor(private http: HttpClient) {
    this.url = window.location.href
  }

  ngOnInit(): void {
    this.playerNameEdit.pipe(
      debounceTime(1000)
    )
    .subscribe(() => {
      this.renamePlayer()
    })
  }

  playerNameEdited() {
    this.playerNameEdit.next()
  }

  renamePlayer() {
    this.http.post(`/api/${this.game}/${this.room}/players`, { player: this.currentPlayer }).subscribe((data) => {
      this.players = data['players']
    })
  }

  acceptPlayerName() {
    this.renamePlayer()
    this.status = 'waiting'
  }

  copyURL() {
    const url = window.location.href

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', url)
      e.preventDefault()
      document.removeEventListener('copy', null)
    })

    document.execCommand('copy')
  }

  start() {
    this.starting.emit({ room: this.room, addComputerPlayer : this.addComputerPlayer})
  }
}
