<div class="home-link">
  <a href="/">
    <i class="material-icons">arrow_back</i>
    All Games
  </a>
</div>

<div [ngSwitch]="status">
  <div *ngSwitchCase="'choosing'">
    <lobby game="codewords"
           title="Codewords"
           [room]="room"
           [currentPlayer]="player"
           [players]="players"
           [status]="status"
           (starting)="start($event)"> </lobby>
  </div>
  <div *ngSwitchCase="'waiting'">
    <lobby game="codewords"
           title="Codewords"
           [currentPlayer]="player"
           [players]="players"
           [status]="status"
           [room]="room"
           (starting)="start($event)"> </lobby>
  </div>
  <div *ngSwitchCase="'playing'">

  </div>
  <div *ngSwitchCase="'finished'">

  </div>
  <div *ngSwitchCase="'expired'">
    <expired game="codewords" title="Codewords"> </expired>
  </div>
  <div *ngSwitchCase="'full'">
    <full game="codewords" title="Codewords"> </full>
  </div>
</div>