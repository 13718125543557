import * as io from 'socket.io-client';
import { toast, Modal } from 'materialize-css'
import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { loadDictionary } from'../words/dictionary'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Component({
  selector: 'bananagrams',
  templateUrl: './bananagrams.html',
  styleUrls: ['./bananagrams.less']
})
@Injectable()
export class BananagramsComponent implements OnInit {

  room:    string          // The name of the current game room we are in, taken from the URL.
  player:  string          // The user name of the player.
  players: string[]        // All players.

  status  = 'loading'      // Game state: 'loading'/'initializing'/'waiting'/'playing'/'finished'/'expired'

  exhaustedLetters = false // Have all letter tiles been taken?

  board:     string[][]    // The state of the game board for this user.
  valid:     boolean[][]   // Which letters are part of valid words on the game board.
  allValid:  boolean       // Are all letters valid on the game board?
  remaining: string        // The letter remaining to be played by this user.
  reserve:   string[][]    // The tile positions of the letters remaining to be played.

  boardWidth    = 15       // Current board width and height (the board can grow if
  boardHeight   = 8        // as the user drops letter.)
  reserveHeight = 3

  url:     string          // Current URL, for sharing other players.
  winner:  string          // Who won the game.
  boards:  any             // Final end game state for each player.

  dictionary: any          // List of valid words, loaded from the server.

  socket: any              // SocketIO connection to server.

  smallify = false         // We shrink the tile size when the board gets too wide or tall.

  constructor(private http:  HttpClient,
              private route: ActivatedRoute) {
    this.url = window.location.href
  }

  ngOnInit(): void {
    this.room = this.route.snapshot.params['room']

    if (this.room) {
      this.loadGameState()
    }
    else {
      this.status = 'initializing'
    }
  }

  loadGameState() {
    console.log("Loading game state")

    this.resetBoard()

    this.http.get('/api/bananagrams/' + this.room)
        .subscribe((data) => {
          this.status = data['status']

          if (this.status == 'expired') {
            return
          }

          this.socket = io('/bananagrams')

          this.player = data['player']

          const board = data['boards'][this.player]
          const hand  = data['hands'][this.player]

          if (board) {
            console.log("Installing board")

            this.board         = board.board
            this.reserve       = board.reserve
            this.boardWidth    = board.boardWidth
            this.boardHeight   = board.boardHeight
            this.reserveHeight = board.reserveHeight
            this.valid         = board.valid
            this.allValid      = board.allValid
            this.remaining     = board.remaining

            this.smallify = this.boardWidth > 20 || this.boardHeight > 13
          }
          else if (hand) {
            this.deal(hand)
          }

          this.setPlayers(data['players'])

          this.winner  = data['winner']
          this.boards  = data['boards']

          this.subscribeForUpdates()
        })

    this.dictionary = loadDictionary(this.http)
  }

  deal(hand) {
    console.log("Dealing hand: " + hand)

    this.remaining = hand.join('')

    this.addToReserve(hand)
  }

  subscribeForUpdates() {
    console.log("Subscribing for updates")

    this.socket.emit('join', this.room)

    this.socket.on('started',   (hands) => { this.started(hands)  })
    this.socket.on('joined',    (data)  => { this.joined(data)    })
    this.socket.on('changed',   (data)  => { this.changed(data)   })
    this.socket.on('peeled',    (peel)  => { this.peeled(peel)    })
    this.socket.on('dumped',    (data)  => { this.dumped(data)    })
    this.socket.on('exhausted', ()      => { this.exhausted()     })
    this.socket.on('finished',  (state) => { this.finished(state) })
  }

  setPlayers(participants) {
    this.players = participants.sort((first, second) => {
      if (first == second) {
        return 0
      }

      if (first == this.player) {
        return -1
      }

      if (second == this.player) {
        return 1
      }

      return first < second ? -1 : 1
    })
  }

  joined(data) {
    if (data.player != this.player && !this.players.includes(data.player)) {
      this.players.push(data.player)

      toast({ html: data.player + ' joined the game!', classes: 'friendly-toast' })
    }
  }

  changed(data) {
    console.log("Players updated: [" + data.players + "], rename is: " + data.renamed + " current player is " + this.player)

    this.setPlayers(data.players)

    if (data.renamed) {
      if (data.renamed.old == this.player || !this.players.includes(this.player)) {
        this.player = data.renamed.new || this.player
      }
    }
  }

  start() {
    this.exhaustedLetters = false

    console.log("Starting game...")

    this.socket.emit('start', this.room, this.player)
  }

  started(hands) {
    this.resetBoard()

    console.log("...started game")

    this.deal(hands[this.player])

    this.status = 'playing'
  }

  peel() {
    console.log("Peeling...")

    this.socket.emit('peel', this.room, this.player)
  }

  peeled(data) {
    if (data.exhausted) {
      this.exhaustedLetters = true

      toast({ html: 'No more tiles!', classes: 'friendly-toast' })
    }
    else {


      toast({html: `${data.player} peeled, ${data.remaining} letter${data.remaining == 1 ? '' : 's'} remaining`, classes: 'friendly-toast'})
    }

    const letter = data['peel'][this.player]

    this.addToReserve(letter)
  }

  dumping(item) {
    const source = item.source
    const letter = source[item.rowNo][item.colNo]

    console.log("Dumping " + letter + " from (" + item.rowNo + ", " + item.colNo + ")")

    this.socket.emit('dump', letter, this.room, this.player)

    source[item.rowNo][item.colNo] = ''

    this.refresh()
  }

  dumped(data) {
    if (data.exhausted) {
      this.exhaustedLetters = true

      toast({ html: 'No more tiles!', classes: 'friendly-toast' })
    }

    if (data.player == this.player) {
      const replacements = data.replacements

      console.log("Dumped letter, and received replacements: " + replacements)

      this.addToReserve(replacements)
    }
  }

  exhausted() {
    this.exhaustedLetters = true
  }

  finished(state) {
    this.status  = 'finished'

    this.setPlayers(state.players)

    this.winner  = state.winner
    this.boards  = state.boards

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    toast({ html: this.winner + ' won!', classes: 'friendly-toast' })
  }

  showRules() {
    const elem = document.getElementById('rules-modal')

    Modal.init(elem).open()
  }

  resetBoard() {
    this.boardWidth    = 15
    this.boardHeight   = 8
    this.reserveHeight = 3
    this.smallify      = false

    this.board   = this.squareArray(this.boardWidth, this.boardHeight, '')
    this.valid   = this.squareArray(this.boardWidth, this.boardHeight, true)
    this.reserve = this.squareArray(this.boardWidth, this.reserveHeight, '')
  }

  addToReserve(letters, overwrite=false) {
    if (overwrite) {
      this.reserve = this.squareArray(this.boardWidth, this.reserveHeight, '')
    }

    let j = 0
    for (let i = 0; j < letters.length; i++) {
      const row = Math.floor(i / this.reserve[0].length)

      if (row > this.reserve.length - 1) {
        break
      }

      const col = i % this.reserve[0].length

      if (this.reserve[row][col] == '') {
        this.reserve[row][col] = letters[j]
        j++
      }
    }

    if (j < letters.length) {
      letters = letters.slice(j)

      this.reserveHeight += 1
      this.reserve.push(new Array(this.reserve[0].length).fill(''))

      this.addToReserve(letters)
      return
    }

    this.saveBoard()
  }

  saveBoard() {
    console.log("Saving board state")

    this.socket.emit('update', {
      room:   this.room,
      player: this.player,
      board: {
        board:         this.board,
        reserve:       this.reserve,
        boardWidth:    this.boardWidth,
        boardHeight:   this.boardHeight,
        reserveHeight: this.reserveHeight,
        valid:         this.valid,
        allValid:      this.allValid,
        remaining:     this.remaining
      }
    })
  }

  dropped(item, dest, rowNo, colNo) {
    this.swap(item.source, item.rowNo, item.colNo, dest, rowNo, colNo)
  }

  swap(source, sourceRowNo, sourceColNo, dest, destRowNo, destColNo) {
    const swap = source[sourceRowNo][sourceColNo]

    source[sourceRowNo][sourceColNo] = dest[destRowNo][destColNo]
    dest[destRowNo][destColNo] = swap

    this.refresh()
  }

  refresh() {
    this.validate()
    this.adjustBoard()
    this.calculateRemainingLetters()
    this.saveBoard()
    this.checkWinCondition()
  }

  squareArray(width, height, defaultValue) {
    return new Array(height).fill('').map(() => { return new Array(width).fill(defaultValue) })
  }

  validate() {
    console.log("Checking spellings...")

    const checkedRows = this.squareArray(this.boardWidth, this.boardHeight, true)
    const checkedCols = this.squareArray(this.boardWidth, this.boardHeight, true)

    let letters = null, allValidSoFar = true

    for (let rowNo = 0; rowNo < this.boardHeight; rowNo++) {
      letters = []

      for (let colNo = 0; colNo < this.boardWidth; colNo++) {
        const letter = this.board[rowNo][colNo]

        if (letter == '') {
          if (letters.length > 0) {
            this.checkValidity(letters, rowNo, colNo - 1, checkedRows, 'horizontal')

            letters = []
          }
        }
        else {
          letters.push(letter)

          if (colNo == this.boardWidth - 1 && letters.length > 0)  {
            this.checkValidity(letters, rowNo, colNo - 1, checkedRows, 'horizontal')
          }
        }
      }
    }

    for (let colNo = 0; colNo < this.boardWidth; colNo++) {
      letters = []

      for (let rowNo = 0; rowNo < this.boardHeight; rowNo++) {
        const letter = this.board[rowNo][colNo]

        if (letter == '') {
          if (letters.length > 0) {
            this.checkValidity(letters, rowNo - 1, colNo, checkedCols, 'vertical')

            letters = []
          }
        }
        else {
          letters.push(letter)

          if (rowNo == this.boardHeight - 1 && letters.length > 0)  {
            this.checkValidity(letters, rowNo - 1, colNo, checkedCols, 'vertical')
          }
        }
      }
    }

    const checked = this.squareArray(this.boardWidth, this.boardHeight, true)

    for (let colNo = 0; colNo < this.boardWidth; colNo++) {
      for (let rowNo = 0; rowNo < this.boardHeight; rowNo++) {
        if (this.board[rowNo][colNo] == '') {
          continue
        }

        if (!checkedRows[rowNo][colNo]) {
          console.log("(row " + rowNo + ", col " + colNo + ") '" +  this.board[rowNo][colNo] + "' is not valid when scanning horizontally")
        }

        if (!checkedCols[rowNo][colNo]) {
          console.log("(row " + rowNo + ", col " + colNo + ") '" +  this.board[rowNo][colNo] + "' is not valid when scanning vertically")
        }

        const valid = checkedRows[rowNo][colNo] && checkedCols[rowNo][colNo]

        if (!valid) {
          console.log("(row " + rowNo + ", col " + colNo + ") '" +  this.board[rowNo][colNo] + "' is not valid")
          allValidSoFar = false
        }

        checked[rowNo][colNo] = valid
      }
    }

    this.allValid = allValidSoFar
    this.valid    = checked
  }

  checkValidity(letters, rowNo, colNo, checked, direction) {
    console.log('Checking letters ' + letters)

    const word = letters.join('')

    if (word.length == 1) {
      return true
    }

    const valid = this.dictionary.contains(word)

    if (!valid) {
      const dX = direction == 'horizontal' ? -1 : 0
      const dY = direction == 'vertical'   ? -1 : 0

      for (let i = 0; i < word.length; i++) {
        checked[rowNo + (i * dY)][colNo + (i * dX)] = false
      }
    }

    return valid
  }

  adjustBoard() {
    this.expandIfNecessary()
    this.shiftIfNecessary()
    this.expandIfNecessary()

    this.smallify = this.boardWidth > 20 || this.boardHeight > 13
  }

  expandIfNecessary() {
    for (let rowNo = 0; rowNo < this.boardHeight; rowNo++) {
      if (this.board[rowNo][this.boardWidth - 1] != '') {
        this.boardWidth += 1

        this.board.forEach((row) => {
          row.push('')
        })

        this.valid.forEach((row) => {
          row.push(false)
        })

        this.reserve.forEach((row) => {
          row.push('')
        })

        break
      }
    }

    for (let colNo = 0; colNo < this.boardWidth; colNo++) {
      if (this.board[this.boardHeight - 1][colNo] != '') {
        this.boardHeight += 1

        this.board.push(new Array(this.boardWidth).fill(''))
        this.valid.push(new Array(this.boardWidth).fill(false))

        break
      }
    }
  }

  shiftIfNecessary() {
    for (let rowNo = 0; rowNo < this.boardHeight; rowNo++) {
      if (this.board[rowNo][0] != '') {
        this.boardWidth += 1

        this.board.forEach((row) => {
          row.unshift('')
        })

        this.valid.forEach((row) => {
          row.unshift(false)
        })

        this.reserve.forEach((row) => {
          row.unshift('')
        })

        break
      }
    }

    for (let colNo = 0; colNo < this.boardWidth; colNo++) {
      if (this.board[0][colNo] != '') {
        this.boardHeight += 1

        this.board.unshift(new Array(this.boardWidth).fill(''))
        this.valid.unshift(new Array(this.boardWidth).fill(false))

        break
      }
    }
  }

  calculateRemainingLetters() {
    const remaining = []

    for (let rowNo = 0; rowNo < this.reserve.length; rowNo++) {
      for (let colNo = 0; colNo < this.reserve[0].length; colNo++) {
        const letter = this.reserve[rowNo][colNo]

        if (letter != '') {
          remaining.push(letter)
        }
      }
    }

    this.remaining = remaining.join('')
  }

  checkWinCondition() {
    if (this.exhaustedLetters && this.allValid && this.reserveIsEmpty() && this.countClusters() == 1) {
      this.socket.emit('end', this.room, this.player)
    }
  }

  reserveIsEmpty() {
    return this.reserve.every((row) => row.every((letter) => letter == '' ))
  }

  shuffleReserve() {
    this.calculateRemainingLetters()

    const shuffled = this.shuffle(this.remaining.split(''))

    this.addToReserve(shuffled.join(''), true)
  }

  shuffle(array) {
    let counter = array.length

    // While there are elements in the array
    while (counter > 0) {
      // Pick a random index
      let index = Math.floor(Math.random() * counter)

      // Decrease counter by 1
      counter--

      // And swap the last element with it
      let temp = array[counter]
      array[counter] = array[index]
      array[index] = temp
    }

    return array
  }

  eraseContiguousLetters(board, rowNo, colNo) {
    if (rowNo < 0 || colNo < 0 || rowNo >= this.boardHeight || colNo >= this.boardWidth || board[rowNo][colNo] == '') {
      return
    }

    board[rowNo][colNo] = ''

    this.eraseContiguousLetters(board, rowNo - 1, colNo)
    this.eraseContiguousLetters(board, rowNo + 1, colNo)
    this.eraseContiguousLetters(board, rowNo, colNo - 1)
    this.eraseContiguousLetters(board, rowNo, colNo + 1)
  }

  countClusters() {
    const board = JSON.parse(JSON.stringify(this.board))
    let clusterCount = 0

    for (let rowNo = 0; rowNo < this.boardHeight; rowNo++) {
      for (let colNo = 0; colNo < this.boardWidth; colNo++) {
        if (board[rowNo][colNo] != '') {
          this.eraseContiguousLetters(board, rowNo, colNo)
          clusterCount++
        }
      }
    }

    console.log("Found " + clusterCount + " clusters")

    return clusterCount
  }
}
