import * as io from 'socket.io-client'
import { Component, OnInit } from '@angular/core'
import { HttpClient        } from "@angular/common/http"
import { ActivatedRoute    } from "@angular/router"
import { debounceTime      } from "rxjs/operators"
import { Subject           } from "rxjs"

@Component({
  selector: 'scattergories',
  templateUrl: './scattergories.html',
  styleUrls: ['./scattergories.less']
})
export class ScattergoriesComponent implements OnInit {

  room:    string       // The name of the current game room we are in, taken from the URL.
  player:  string       // The user name of the player whose browser we are in.
  players: string[]     // All players.
  scores:  any          // Current scoring for all players.

  letter:     string    // Current letter we are filling categories for.
  categories: string[]  // The current set of categories.
  words:      string[]  // Words submitted by this player.

  judging:    any       // During the voting round, the words we are up- and down-voting.
  duplicates: any       // Any duplicates submitted by multiple players, that automatically score zero.
  category:   string    // The current category we are voting on.
  votes:      any       // Votes by player who voted, by category, then who they voted for.

  round:      number    // The round length in seconds.
  remaining:  number    // The number of seconds remaining in this round (calculated).
  countingDown = false  // Flag to indicate the countdown has started.

  status = 'loading'    // Game state: 'loading'/'choosing'/'waiting'/'playing'/'voting'/'finished'/'expired'/'full'

  typing:  any          // Array of listeners for words the player has entered.

  socket:  any          // SocketIO connection to server.

  constructor(private http:  HttpClient,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.room = this.route.snapshot.params['room']

    if (this.room) {
      this.loadGameState()
    }
    else {
      this.status = 'initializing'
    }

    this.typing = [
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>(),
      new Subject<string>()
    ]

    for (let i = 0; i < this.typing.length; i++) {
      this.typing[i].pipe(
        debounceTime(1000)
      )
      .subscribe(() => {
        this.saveWord(i)
      })
    }
  }

  loadGameState() {
    console.log("Loading game state")

    this.http.get('/api/scattergories/' + this.room)
      .subscribe((data) => {
        this.status = data['status']

        if (this.status == 'expired') {
          return
        }

        this.socket = io('/scattergories')

        this.player     = data['player']
        this.players    = data['players']
        this.scores     = data['scores']
        this.round      = data['round']
        this.remaining  = data['remaining']  || data['round']
        this.letter     = data['letter']
        this.categories = data['categories'] || []
        this.words      = data['words']      || []
        this.category   = data['category']
        this.judging    = data['judging']
        this.duplicates = data['duplicates']
        this.votes      = data['votes']
        this.scores     = data['scores']

        this.subscribeForUpdates()

        if (this.status == 'playing') {
          this.startCountdown()
        }
      })
  }

  subscribeForUpdates() {
    console.log("Subscribing for updates")

    this.socket.emit('join', this.room)

    this.socket.on('started', (data) => { this.started(data)  })
    this.socket.on('changed', (data) => { this.changed(data)  })
    this.socket.on('voting',  (data) => { this.voting(data)   })
    this.socket.on('voted',   (data) => { this.voted(data)    })
    this.socket.on('finished',(data) => { this.finished(data) })
  }

  changed(data) {
    console.log("Players updated: [" + data.players + "], rename is: " + data.renamed + " current player is " + this.player)

    this.players = data.players

    if (data.renamed) {
      if (data.renamed.old == this.player || !this.players.includes(this.player)) {
        this.player = data.renamed.new || this.player
      }
    }
  }

  startCountdown() {
    if (!this.countingDown) {
      setInterval(() => {
        if (this.remaining <= 1) {
          if (this.status == 'playing') {
            this.complete()
          }
        }
        else {
          this.remaining -= 1
        }
      }, 1000)

      this.countingDown = true
    }
  }

  start() {
    this.socket.emit('start', this.room, this.player)
  }

  started(data) {
    console.log("The game has started!")

    this.status     = 'playing'
    this.remaining  = 120
    this.round      = 120
    this.letter     = data.letter
    this.categories = data.categories
    this.words      = [].fill('', 0, 12)

    this.startCountdown()
  }

  wordEdited(event, row) {
    this.typing[row].next()
  }

  saveWord(i) {
    const category = this.categories[i]
    const word     = this.words[i]

    console.log(`Saving word ${word} in category ${category}`)

    this.socket.emit('choose', this.room, this.player, category, word)
  }

  complete() {
    this.socket.emit('complete', this.room)
  }

  voting(data) {
    this.status     = 'voting'

    this.category   = data['category']
    this.judging    = data['judging']
    this.duplicates = data['duplicates']
    this.votes      = data['votes']
  }

  voted(data) {
    this.votes = data.votes
  }

  upvote(player) {
    this.socket.emit('vote', this.room, this.player, +1, this.category, player)
  }

  downvote(player) {
    this.socket.emit('vote', this.room, this.player, -1, this.category, player)
  }

  scoreFor(category, candidate) {
    let votes = this.votes

    return this.players.reduce((total, player) => {
      return total + votes[player][category][candidate]
    }, 0)
  }

  next() {
    this.socket.emit('next', this.room)
  }

  finished(data) {
    this.status = 'finished'
    this.scores = data.scores
  }
}
