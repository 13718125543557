import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'full',
  templateUrl: './full.html',
  styleUrls: ['./full.less']
})
export class FullComponent {

  /**
   * Component shown when a game is oversubscribed.
   */

  @Input() title: string;
  @Input() game:  string;

  constructor() { }

}
