import { NgModule                } from '@angular/core';
import { BrowserModule           } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule        } from '@angular/common/http';
import { FormsModule             } from '@angular/forms';
import { DragDropModule          } from '@angular/cdk/drag-drop';

import { AppRoutingModule        } from './app-routing.module';
import { AppComponent            } from './app';
import { HomeComponent           } from './home/home';
import { BoggleComponent         } from './boggle/boggle';
import { ScattergoriesComponent  } from './scattergories/scattergories';
import { CodewordsComponent      } from './codewords/codewords';
import { TimerComponent          } from './timer/timer';
import { BananagramsComponent    } from './bananagrams/bananagrams';
import { YahtzeeComponent        } from './yahtzee/yahtzee';
import { YahtzeeScoreComponent   } from "./yahtzee/score/score";
import { YahtzeePlayerComponent  } from "./yahtzee/player/player";
import { LobbyComponent          } from './lobby/lobby';
import { ExpiredComponent        } from './expired/expired';
import { FullComponent           } from './full/full';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BoggleComponent,
    ScattergoriesComponent,
    CodewordsComponent,
    TimerComponent,
    BananagramsComponent,
    YahtzeeComponent,
    YahtzeeScoreComponent,
    YahtzeePlayerComponent,
    LobbyComponent,
    ExpiredComponent,
    FullComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DragDropModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
