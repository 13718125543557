<div class="home-link">
  <a href="/">
    <i class="material-icons">arrow_back</i>
    All Games
  </a>
</div>

<div [ngSwitch]="status">
  <div *ngSwitchCase="'choosing'">
    <div class="container">
      <h1 class="header center">
        {{ title }}
      </h1>

      <div class="section players">
        <div class="row center">
          <div class="label">
            Pick a username:
          </div>

          <input type="text"
                 class="user-name"
                 maxlength="20"
                 [(ngModel)]="currentPlayer"
                 (ngModelChange)="playerNameEdited($event)"
                 autofocus />

          <button class="waves-effect waves-light btn-flat"
                  (click)="acceptPlayerName()">Accept</button>

        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'waiting'">
    <div class="container">
      <h1 class="header center">
        {{ title }}
      </h1>

      <div class="section players">
        <div class="row center">
          <div class="label">
            Pick a username:
          </div>

          <input type="text"
                 class="user-name"
                 maxlength="20"
                 [(ngModel)]="currentPlayer"
                 (ngModelChange)="playerNameEdited($event)"
                 autofocus />

          <div class="row computer-opponent" *ngIf="computerPlayerOption">
            <label>
              <input name="players" type="checkbox" value="computer" [(ngModel)]="addComputerPlayer"/>
              <span>Add a Computer Player</span>
            </label>
          </div>

          <div class="player-list" *ngIf="players.length > 1">
            <div class="label">
              Players so far:
            </div>
            <ul>
              <li *ngFor="let participant of players">
                {{ participant }}
              </li>
            </ul>
          </div>

          <div class="label">
            Share this link with friends you want to play against:
          </div>

          <input type="text" class="share-link" value="{{ url }}" disabled />

          <button (click)="copyURL()" class="btn btn-small btn-flat">
            Copy
          </button>

          <div>
            <button class="waves-effect waves-light btn-large" (click)="start()">
              Start!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>