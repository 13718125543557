import * as io from 'socket.io-client'
import { Component, OnInit } from '@angular/core';
import { HttpClient        } from "@angular/common/http";
import { ActivatedRoute    } from "@angular/router";

@Component({
  selector: 'codewords',
  templateUrl: './codewords.html',
  styleUrls: ['./codewords.less']
})
export class CodewordsComponent implements OnInit {

  room:    string     // The name of the current game room we are in, taken from the URL.
  player:  string     // The user name of the player whose browser we are in.
  players: string[]   // All players.
  scores:  any        // Current scoring for all players.

  socket:  any        // SocketIO connection to server.

  status = 'loading'  // Game state: 'loading'/'choosing'/'waiting'/'playing'/'finished'/'expired'

  constructor(private http:  HttpClient,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.room = this.route.snapshot.params['room']

    if (this.room) {
      this.loadGameState()
    }
    else {
      this.status = 'initializing'
    }
  }

  loadGameState() {
    console.log("Loading game state")

    this.http.get('/api/codewords/' + this.room)
      .subscribe((data) => {
        this.status = data['status']

        if (this.status == 'expired') {
          return
        }

        this.socket  = io('/codewords')

        this.player  = data['player']
        this.players = data['players']
        this.scores  = data['scores']

        this.subscribeForUpdates()
      })
  }

  subscribeForUpdates() {
    console.log("Subscribing for updates")

    this.socket.emit('join', this.room)

    this.socket.on('started', (data) => { this.started(data) })
  }

  start(data) {

  }

  started(data) {

  }
}
