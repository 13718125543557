import { Component, OnChanges, Input, ViewChild, ElementRef, Renderer2, Injectable } from '@angular/core';

@Component({
  selector: 'timer',
  templateUrl: './timer.html',
  styleUrls: ['./timer.less']
})
@Injectable()
export class TimerComponent implements OnChanges {
  @Input() round: number;
  @Input() remaining: number;

  @ViewChild('progress') progress: ElementRef;

  formattedTime: string;

  constructor(private renderer: Renderer2) {
  }

  ngOnChanges(): void {
    this.formattedTime = new Date((this.remaining) * 1000).toISOString().substr(15, 4)

    const proportion = this.remaining / this.round
    const arc = describeArc(50, 50, 60, 0, (1.0 - proportion) * 360)

    if (this.progress != null) {
      this.renderer.setAttribute(this.progress.nativeElement, "d", arc)
    }
  }
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  }
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end   = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = 1

  return [
    "M", 50, 50,
    "L", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ")
}
